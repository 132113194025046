












import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";
import ContainerHeaderTitle from "../components/container-header-title/index.vue";
import { checkIsMicroApp } from "@/scripts/load-micro-app";

interface Vdata {
  name: string;
}

@Component({
  components: {
    ContainerHeaderTitle,
  },
})
export default class SettingPush extends Vue {
  // data
  private data: Vdata = {
    name: "事件推送设置",
  };

  // watch
  @Watch("$route", { immediate: true, deep: true })
  routeChange(newValue: any, oldValue: any) {
    this.data.name = newValue.meta.title;
    this.$nextTick(() => {
      checkIsMicroApp.bind(this)(); // 加载微应用
    });
  }

  // methods
  private mounted(): void {
    // this.$nextTick(() => {
    //     checkIsMicroApp.bind(this)(); // 加载微应用
    // })
  }
}
