import { loadMicroApp } from 'qiankun';
import microApps from '../micro-app';


interface MicroConfigItem {
    trictStyleIsolation: boolean;
    experimentalStyleIsolation: boolean; // 入口
}

// 检测是否是微应用
export function checkIsMicroApp(this:any): void {
    // let that = ;
    let meta = this.$route.meta;
    console.log(meta)
    if(meta.isMoicroApp){
        let name = meta.name; // 获取微应用 name
        let info = microApps.filter(v => v.name == name)[0];
        console.log(microApps,name,info);
        let microApp =  loadMicroApp(info); // strictStyleIsolation: true,   { strictStyleIsolation: true, experimentalStyleIsolation: true  }
        console.log(47,microApp.getStatus())
    }
};


export default {};